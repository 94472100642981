

.hero {
	padding: 132px 0 150px;
	background-color: #DFE7E8;

	h1 {
		margin-bottom: 70px;
	}

	@media (max-width: 992px) {
		padding: 50px 0;

		h1 {
			margin-bottom: 50px;
			font-size: 40px;
			line-height: 1.2;
		}

		a {
			font-size: 16px;
		}
	}

	@media (max-width: 768px) {
		h1 {
			margin-bottom: 40px;
			font-size: 30px;
		}
	}
}

.hero--approach {
	padding: 63px 0 200px;

	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 180px 58px;
	}

	.o-container {
		>p {
			margin-bottom: 70px;
		}
	}

	h2 {
		margin-bottom: 136px;
	}

	h3 {
		margin-bottom: 10px;
	}

	@media (max-width: 1100px) {
		padding: 50px 20px;

		h2 {
			font-size: 28px;
			line-height: 1.2;
			margin-bottom: 30px;
		}

		h3 {
			font-size: 20px;
			line-height: 1.2;
		}

		a {
			font-size: 16px;
		}

		.o-container {
		}

		.o-container {
			padding: 0;
			
			>p {
				margin-bottom: 30px;
			}
		}

		.grid {
			grid-template-columns: 1fr;
			gap: 30px;

			p {
				margin-bottom: 0;
			}
		}
	
	}
}

.hero--about {
	padding: 63px 86px 124px;

	.img-main-wrapper {
		margin-bottom: 52px;
		margin-top: 32px;

		@media (max-width: 1100px) {
			margin: 25px 0;
		}
	}

	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 80px 30px; 

		@media (max-width: 1100px) {
			grid-template-columns: 1fr;
			gap: 30px; 
		}
	}

	.grid__item {
		display: flex;	

		.numb {
			font-size: 100px;
			color: #203650;
			opacity: 0.3;
			margin-right: 16px;
			min-width: 124px;
			font-weight: 500;

			@media (max-width: 1100px) {
				font-size: 50px;
				min-width: 60px;
			}

			@media (max-width: 768px) {
				font-size: 35px;
				min-width: 50px;
			}
		}	
	}

	p.font-500 {
		margin-bottom: 25px;
	}

	h2.font-500 {
		margin-bottom: 50px;
	}

	h3 {
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 0;
	}

	@media (max-width: 1100px) {
		padding: 50px 20px;

		h2 {
			font-size: 28px;
			line-height: 1.2;
			margin-bottom: 30px;
		}

		h3 {
			font-size: 20px;
			line-height: 1.2;
		}

		a {
			font-size: 16px;
		}

		.o-container {
			padding: 0;
		}
	
	}
}

.hero--contact {
	padding: 95px 0 207px;

	ul {
		margin-bottom: 60px;
		
		li {
			font-size: 18px;
			line-height: 24px;
		}

		a {
			font-weight: 400;
		}
	}

	button {
		margin-top: 70px;
	}

	@media (max-width: 1100px) {
		padding: 50px 0;

		ul {
			margin-bottom: 20px;
		}

		button {
			margin-top: 20px;
		}
	}
}

.sub-hero {
	background-color: #213651;
	padding: 85px 110px;
	display: flex;
	align-items: center;
	justify-content: space-between;


	img {
		min-width: calc(50% - 40px);
		margin-right: 40px;
	}

	article {
		max-width: 750px;
	}

	h2 {
		color: #fff;
		margin-bottom: 70px;
	}

	h3 {
		color: #fff;
	}

	a {
		color: #fff;
	}

	@media (max-width: 1100px) {
		padding: 50px 20px;

		h2 {
			font-size: 28px;
			line-height: 1.2;
			margin-bottom: 30px;
		}

		h3 {
			font-size: 20px;
		}

		a {
			font-size: 16px;
		}
	}

	@media (max-width: 991px) {
		flex-direction: column;

		img {
			margin-right: 0;
			margin-bottom: 40px;
		}
	}
}

.modeling {
	background-color: #DFE7E8;
	display: grid;
	grid-template-columns: 1fr 1fr;

	.img-wrapper {
		height: 100%;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
	}

	article {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		padding: 230px 200px 230px 86px;

		@media (max-width: 1440px) {
			padding: 130px 100px 130px 50px;
		}

		@media (max-width: 1100px) {
			padding: 50px;
		}

		@media (max-width: 991px) {
			padding: 50px 20px;
		}
	}

	h3 {
		margin-bottom: 70px;
	}

	p {
		margin-bottom: 70px;
		line-height: 24px;
	}

	@media (max-width: 1100px) {

		h3 {
			margin-bottom: 30px;
		}

		p {
			margin-bottom: 30px;
			font-size: 16px;
		}

		a {
			font-size: 16px;
		}
	}

	@media (max-width: 991px) {
		grid-template-columns: 1fr;

		.img-wrapper {
			height: auto;
			aspect-ratio: 1/1;
		}
	}
}

.trials {
	padding: 110px 0 150px;
	background-color: #C3DDE2;

	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 84px;
		margin-top: 60px;
	}

	img {
		margin-top: 50px;
	}

	h2 {
		margin-bottom: 40px;
	}

	@media (max-width: 1100px) {
		padding: 50px 20px;

		.grid {
			grid-template-columns: 1fr;
			gap: 30px;
			margin-top: 30px;
		}

		h2 {
			font-size: 28px;
			line-height: 1.2;
			margin-bottom: 30px;
		}

		img {
			margin-top: 3px;
		}

		.o-container {
			padding: 0;
		}
	}

}