
.header-main {
    background-color: #DFE7E8;
	padding: 12px 148px 12px 86px;
	position: sticky;
	top: 0;
	z-index: 99999;
	box-shadow: 0px 3px 6px #00000029;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1100px) {
		padding: 12px 20px;
	}
}

.header-main__bars {
	font-size: 30px;
	color: #000;
	display: none;
	
	@media (max-width: 992px) {
		display: block;
	}
}

.header-main__logo {
	display: flex;
	align-items: center;

	@media (max-width: 1100px) {
		svg {
			width: 50px;
			height: 50px;
		}
	}
}

.header-nav {
	@media (max-width: 992px) {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		padding: 20px;
		background-color: #DFE7E8;
		visibility: hidden;
		opacity: 0;
		transition: all .3s ease;
		
		&.active {
			visibility: visible;
			opacity: 1;
		}
	}
}

.nav__list {
	margin-bottom: 0;
	
	@media (max-width: 992px) {
		display: block;
	}
}

.nav__list-item {
	
	& + .nav__list-item {
		margin-left: 50px;
		
		@media (max-width: 992px) {
			margin-left: 0;
			margin-top: 10px;
		}
	}
}

.nav__link {
	font-size: 20px;
	color: #213651;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 500;

	@media (max-width: 991px) {
		font-size: 16px;
	}
}

.bars {
	display: none;
	width: 25px;
	height: 20px;
	margin-top: 2px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;

	&:hover,
	&:focus {
		color: #213651;
	}

	span { 
	  display: block;
	  position: absolute;
	  height: 3px;
	  width: 100%;
	  background: #213651;
	  border-radius: 2px;
	  opacity: 1;
	  left: 0;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: .25s ease-in-out;
	  -moz-transition: .25s ease-in-out;
	  -o-transition: .25s ease-in-out;
	  transition: .25s ease-in-out;
	}


	span:nth-child(1) {
	  top: 0px;
	}

	span:nth-child(2),span:nth-child(3) {
	  top: 8px;
	}

	span:nth-child(4) {
	  top: 16px;
	}

	&.active span:nth-child(1) {
	  top: 10px;
	  width: 0%;
	  left: 50%;
	}

	&.active span:nth-child(2) {
	  -webkit-transform: rotate(45deg);
	  -moz-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
	  transform: rotate(45deg);
	}

	&.active span:nth-child(3) {
	  -webkit-transform: rotate(-45deg);
	  -moz-transform: rotate(-45deg);
	  -o-transform: rotate(-45deg);
	  transform: rotate(-45deg);
	}

	&.active span:nth-child(4) {
	  top: 10px;
	  width: 0%;
	  left: 50%;
	}

	@media (max-width: 992px) {
		display: block;
	}
}