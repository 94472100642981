.button {
  -webkit-font-smoothing: inherit;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  color: $color-base;
  transition: background-color .2s ease, color .2s ease;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: all .2s ease;
  background-color: transparent;
  cursor: pointer;
  font-family: $base-font-family;
  text-decoration: none;
  line-height: 1;

  &:not(:disabled) {

    &:hover {}

    &:focus {}

    &:active {
      outline: none;
      box-shadow: none !important;
      text-decoration: none;
    }
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed !important;
  }
}


.button--primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: #213651;
  width: 210px;
  padding: $btn--primary-padding;
  color: #fff;
  font-size: $base-font-size;
  text-decoration: none;

  &:not(:disabled) {

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#213651, 10%);
    }
  }
}