.footer-main {
    background-color: #213651;
    padding: 0 86px;

    @media (max-width: 1100px) {
      svg {
        width: 50px;
        height: 50px;
      }
    }

    .pre-footer {
      padding: 80px 0 65px;
      border-bottom: 1px solid rgba(#FFFFFF, 0.34);

      svg {
        margin-bottom: 45px;
      }

      h3 {
        color: #fff;
        margin-bottom: 35px;
      }

      a {
        color: #fff;
      }

      @media (max-width: 991px) {
        padding: 30px 0;

        svg {
          margin-bottom: 15px;
        }

        h3 {
          font-size: 18px;
          line-height: 1.2;
        }

        a {
          font-size: 16px;
        }
      }
    }

    .nav-footer {
      padding: 57px 0;

      li {
        color: #fff;
        margin: 0 10px;
      }

      a {
        color: #fff;
        font-weight: 400;
      }

      @media (max-width: 991px) {
        padding: 10px 0 20px;

        a {
          font-size: 16px;
        }

        ul {
          flex-wrap: wrap;
        }

        li {
          font-size: 16px;
          margin-top: 10px;

        }
      }
    }

    @media (max-width: 991px) {
      padding: 0 20px;
    }
}