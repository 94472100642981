$color-white: #fff;
$color-black: #213651;
$color-base: $color-black;

$color-text: $color-base;

$color-text-active: $color-black;
$color-text-click: lighten($color-base, 10%);
$color-link: $color-base;

$color-btn: $color-base;
$color-btn-hover: darken($color-base, 5%);
$color-btn-focus: darken($color-base, 10%);
$color-btn-active: darken($color-base, 15%);

$color-input: $color-black;
$color-input-placeholder: rgba($color-black, .5);
$color-border: $color-black;