
/*------------------------------------*\
    #TRUMPS - HELPERS
\*------------------------------------*/

.is-hidden {
    display: none !important;
}

.is-invisible {
    visibility: hidden !important;
}

.is-scrollable-y {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.is-scrollable-x {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.u-no-transition {

    &,
    * {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -ms-transition: none !important;
        transition: none !important;
    }
}

.u-full-heght {
    height: 100% !important;
}

.u-text-center {
    text-align: center !important;
}

.u-text-left {
    text-align: left !important;
}

.u-text-right {
    text-align: right !important;
}

.u-align-middle {
    vertical-align: middle !important;
}

.u-align-top {
    vertical-align: top !important;
}

.u-align-bottom {
    vertical-align: bottom !important;
}

.u-float-left {
    float: left !important;
}

.u-float-right {
    float: right !important;
}

.u-inline-block {
    display: inline-block !important;
}

.u-display-block {
    display: block !important;
}

.italic {
    font-style: italic!important;
}

.w-100 {
    width: 100%!important;
}
.relative {
    position: relative!important;
}

.font-500 {
    font-weight: 500!important;
}
.font-600 {
    font-weight: 600!important;
}

.clr-blue {
    color: #1678B4!important;
}