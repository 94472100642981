/**
* Reset input styles.
*/
.input {
  -webkit-appearance: none;
  /* removing inner shadow on iOS inputs */
  -webkit-font-smoothing: inherit;
  box-sizing: border-box;
  transition: all .5s ease;
  background: none;
  border: none;
  color: $color-base;
  width: 100%;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  padding: 0;
  /* remove browser defaults */
  vertical-align: middle;
  width: 100%;

  &:invalid {
    box-shadow: none;
    /* fixes mozilla red outline (shadow) */
  }

  &[readonly] {
    cursor: text;
  }

  &::-ms-clear {
    display: none;
    /* remove IE clear icon */
  }

  @mixin input-placeholder {
    color: $color-base;
  }

  &:not(:disabled) {

    &:hover {}

    &:focus {
      outline: none;
    }
  }
}

.input--primary {
  height: 68px;
  padding: 0;
  color: $color-input;
  display: flex;
  font-size: 18px;
  border-bottom: 1px solid #213651;

  &:not(:disabled) {

    &:hover {}

    &:focus {}

    @mixin input-placeholder {
      color: $color-input-placeholder;
    }
  }

  /**
   * @disabled
   */
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color-input;
    opacity: 1;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color-input;
    opacity: 1;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color-input;
    opacity: 1;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color-input;
    opacity: 1;
  }

  @media (max-width: 1100px) {
    font-size: 16px;
    height: 46px;
  }
}

.input-box {
  margin-bottom: 35px;
  position: relative;

  @media (max-width: 1100px) {
    margin-bottom: 20px;
  }
}

.input--textarea {
  min-height: 100px;
  resize: none;
}